import React, { useEffect } from 'react'

// hooks
import useGlotio from 'hooks/useGlotio'
import { useCreateModal } from 'hooks/useCreateModal'

// constants
import {
  CONFIRM_MODAL,
  FAIL_RETRY_SUBSCRIPTION_PAYMENT_MODAL,
  RETRY_SUBSCRIPTION_PAYMENT_MODAL
} from 'constants/modals.constants'
import { ROUTES } from 'constants/routes.constants'
import { TARGET_TYPE } from 'constants/target'

// utils
import { delay } from 'utils/delay'

import styles from './index.module.scss'


export const PaymentErrorHandlerModals = ({ history, currentSubscription }) => {
  const { translateText } = useGlotio()
  const { onOpenModal, onCloseModal, onNextModal } = useCreateModal()
  const isBlankTarget = window.location !== window.parent.location

  useEffect(() => {
    showPaymentErrorModal()
  }, [])


  function goToPayment() {
    if (isBlankTarget) {
      window.open(ROUTES.PAYMENTS_BILLING, TARGET_TYPE.BLANK)
      return
    }
    onCloseModal()
    history.push(ROUTES.PAYMENTS_BILLING)
  }

  function showPaymentErrorModal () {
    const plan = `<span class="${styles.highlight_text}">${translateText('Plan {plan}', {plan: 'Free'})}</span>`
    const modal = {
      type: CONFIRM_MODAL,
      closable: false,
      maskClosable: false,
      title: translateText('Payment method error'),
      description: <p dangerouslySetInnerHTML={{__html: translateText('There seems to have been an error in the payment method entered. If you prefer, you can try again or change your payment details. If you do not complete the payment, your Glotio account will automatically be charged the {plan}.', {plan})}} />,
      buttons: [
        {
          text: translateText('Modify payment details'),
          options: {
            type: 'button',
            variant: 'outlined',
            size: 'large',
            className: styles.modal_button
          },
          callback: () => goToPayment()
        },
        {
          text: translateText('Retry payment'),
          options: {
            type: 'button',
            variant: 'default',
            size: 'large',
            className: styles.modal_button
          },
          callback: () => showRetryPaymentModal()
        },
      ]
    }

    onOpenModal({...modal})
  }

  function showRetryPaymentModal () {
    const modal = {
      type: RETRY_SUBSCRIPTION_PAYMENT_MODAL,
      closable: false,
      maskClosable: false,
      onFailRetryPayment: showFailRetryModal
    }

    onNextModal({...modal})
  }


  function showFailRetryModal() {
    const modal = {
      type: FAIL_RETRY_SUBSCRIPTION_PAYMENT_MODAL,
      closable: false,
      maskClosable: false,
      currentSubscription,
      onSuccessCancelSubscription: async () => {
        await delay(1000)
        window.location.reload()
      },
      onModifyPayment: goToPayment
    }

    onNextModal({...modal})
  }

  return null
}
