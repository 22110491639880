import React from 'react'
import { get } from 'lodash'
import Loading from 'components/GlotioComponents/Asistent/Steps/Loading'
import Processing from 'components/GlotioComponents/Wizard/Processing'
import TranslationWizard from 'components/GlotioComponents/Wizard/TranslationWizard'
import LenguageUpdateHandler from 'components/GlotioComponents/Asistent/Steps/Update/LenguageUpdateHandler'
import moment from 'moment'

/**
 * Defines the structure of an API response
 *
 * @typedef APIResponse
 * @property {boolean} success
 * @property {string} message
 * @property {Object<string, any>} object
 */

/**
 * Defines the structure of a Process object
 *
 * @typedef ProcessObject
 * @property {string} processID
 * @property {string} status
 * @property {string} currentSubProcess
 * @property {string} progress
 * @property {PipeProcessStep[]} pipeProcess
 * @property {string} subProgress
 * @property {string} created
 * @property {string} updated
 */

/**
 * Defines the structure of a PipeProcess step
 *
 * @typedef {Object<string, string>} PipeProcessStep
 * @property {string} processName
 */

export default class Process {
  /**
   * List of status names
   */
  static status = {
    // STANDARD STATUS
    WAITING: 'WAITING',
    FINISHED: 'FINISHED',
    PROCESSING: 'PROCESSING',
    // ERROR STATUS
    ERROR: 'ERROR',
    RETRY: 'RETRY',
    TIMEOUT: 'TIMEOUT',
    // ABORTED STATUS
    ABORTED: 'ABORTED',
    ABORTED_MAX_ATTEMPTS: 'ABORTED_MAX_ATTEMPTS',
    ABORTED_EXPIRED_ESTIMATE: 'ABORTED_EXPIRED_ESTIMATE',
    ABORTED_EXCEEDED_ACCOUNT_LIMIT: 'ABORTED_EXCEEDED_ACCOUNT_LIMIT',
    ABORTED_NOTHING_TO_TRANSLATE: 'ABORTED_NOTHING_TO_TRANSLATE',
    ABORTED_DOWNLOAD_FAILED_API_CONNECTION: 'ABORTED_DOWNLOAD_FAILED_API_CONNECTION',
    ABORTED_REMOTE_DEFAULT_LANGUAGE_CHANGED: 'ABORTED_REMOTE_DEFAULT_LANGUAGE_CHANGED',
    ABORTED_RETRY_NOT_ADVANCE: 'ABORTED_RETRY_NOT_ADVANCE',
    ABORTED_CHANGED_CONFIGURATION: 'ABORTED_CHANGED_CONFIGURATION',
    ABORTED_REMOTE_LANGUAGE_ISO_CHANGED: 'ABORTED_REMOTE_LANGUAGE_ISO_CHANGED',
    ABORTED_REMOTE_LANGUAGE_DELETED: 'ABORTED_REMOTE_LANGUAGE_DELETED',
    ABORTED_SOURCE_LANG_NO_TRANSLATABLE: 'ABORTED_SOURCE_LANG_NO_TRANSLATABLE',
    ABORTED_FAILED_ADD_LANGUAGE: 'ABORTED_FAILED_ADD_LANGUAGE',
    ABORTED_NOT_ENOUGH_CHARS_BALANCE: 'ABORTED_NOT_ENOUGH_CHARS_BALANCE'
  }

  /**
   * List of subprocesses names
   */
  static subProcess = {
    DOWNLOAD: 'DOWNLOAD',
    ESTIMATE_COLLECTOR: 'ESTIMATE_COLLECTOR',
    TRANSLATION_COLLECTOR: 'TRANSLATION_COLLECTOR',
    SUM_COLLECTOR: 'SUM_COLLECTOR',
    ASK_LANGS_TO_TRANSLATE: 'ASK_LANGS_TO_TRANSLATE',
    ASK_PAYMENTS_LIMITS: 'ASK_PAYMENTS_LIMITS',
    TRANSLATION: 'TRANSLATION',
    INSTALL_LANGUAGES: 'INSTALL_LANGUAGES',
    UPLOAD: 'UPLOAD',
  }

  static processStatus = {
    DOWNLOAD: [Process.subProcess.DOWNLOAD],
    ANALYSE: [Process.subProcess.ESTIMATE_COLLECTOR, Process.subProcess.TRANSLATION_COLLECTOR, Process.subProcess.SUM_COLLECTOR],
    INSTALL_LANGUAGES: [Process.subProcess.INSTALL_LANGUAGES],
    TRANSLATE: [Process.subProcess.TRANSLATION, Process.subProcess.UPLOAD]
  }

  static excludeAborts = [Process.status.ABORTED_NOTHING_TO_TRANSLATE, Process.status.ABORTED_EXPIRED_ESTIMATE,]

  /**
   * Titles for Assistant screen
   */
  static subProcessTitles = {
    DOWNLOAD: 'Downloading texts',
    COLLECTOR: 'Analyzing texts',
    SUM_COLLECTOR: 'Analyzing texts',
    ASK_LANGS_TO_TRANSLATE: 'Analyzing texts',
    ASK_PAYMENTS_LIMITS: 'Updating texts',
    TRANSLATION: 'Translating texts',
    UPLOAD: 'Updating texts',
  }

  /**
   * Titles for Assistant screen
   */
  static subProcessExplanations = {
    DOWNLOAD: 'We are downloading the contents of your store',
    COLLECTOR: 'We are analyzing the texts of your store',
    SUM_COLLECTOR: 'We are analyzing the texts of your store',
    ASK_LANGS_TO_TRANSLATE: 'We are analyzing the texts of your store',
    ASK_PAYMENTS_LIMITS: 'We are updating your store texts with the new translations',
    TRANSLATION: 'We are translating the texts into the selected languages',
    UPLOAD: 'We are updating your store texts with the new translations',
  }

  /**
   * List of the different process types that can be received from the server
   */
  static processType = {
    firstTranslate: 'COFT_',
    update: 'COUD_',
    replace: 'CORE_',
    translate: 'COTR_',
  }

  static statusBeforeTranslation = [
    Process.subProcess.DOWNLOAD,
    Process.subProcess.ESTIMATE_COLLECTOR,
    Process.subProcess.SUM_COLLECTOR,
    Process.subProcess.ASK_LANGS_TO_TRANSLATE,
    Process.subProcess.ASK_PAYMENTS_LIMITS
  ]

  static statusOfTranslating = [
    Process.subProcess.INSTALL_LANGUAGES,
    Process.subProcess.TRANSLATION_COLLECTOR,
    Process.subProcess.SUM_COLLECTOR,
    Process.subProcess.TRANSLATION,
    Process.subProcess.UPLOAD
  ]

  /**
   * List of different abortion statuses that can be received from the server
   */
  static failedStatusList = [
    // generic abort screen
    Process.status.ABORTED,
    Process.status.ABORTED_MAX_ATTEMPTS,
    Process.status.ABORTED_RETRY_NOT_ADVANCE,

    // install new langs screen
    Process.status.ABORTED_NOTHING_TO_TRANSLATE,

    // custom abort screens
    Process.status.ABORTED_EXPIRED_ESTIMATE,
    Process.status.ABORTED_EXCEEDED_ACCOUNT_LIMIT,
    Process.status.ABORTED_DOWNLOAD_FAILED_API_CONNECTION,
    Process.status.ABORTED_REMOTE_DEFAULT_LANGUAGE_CHANGED,
    Process.status.ABORTED_CHANGED_CONFIGURATION,
    Process.status.ABORTED_REMOTE_LANGUAGE_ISO_CHANGED,
    Process.status.ABORTED_REMOTE_LANGUAGE_DELETED,
    Process.status.ABORTED_SOURCE_LANG_NO_TRANSLATABLE,
    Process.status.ABORTED_FAILED_ADD_LANGUAGE
  ]

  /**
   * Checks if the process has an abortion code
   * @param {ProcessObject} currentProcess The current  process object
   * @returns {boolean}
   */
  static hasProcessAborted(currentProcess) {
    const { failedStatusList } = Process

    if (!currentProcess || !currentProcess.status) return false

    return (
      failedStatusList.includes(currentProcess.status) || currentProcess.status.includes('ABORTED_')
    )
  }

  static isExcludeAborted(currentProcess) {
    const status = get(currentProcess, 'status', undefined)
    return Process.excludeAborts.includes(status)
  }

  static isAbortedNothingToTranslate(currentProcess) {
    const status = get(currentProcess, 'status', undefined)
    return Process.status.ABORTED_NOTHING_TO_TRANSLATE === status
  }

  /**
   * Checks if the status received is from an aborted process
   * @param {string} status
   * @returns {boolean}
   */
  static isAbortedStatus = (status) => {
    if (!status || typeof status !== 'string') return false

    const { failedStatusList } = Process
    return failedStatusList.includes(status) || status.includes('ABORTED_')
  }

  /**
   * Checks if the status received is from an error process
   * @param {string} status
   * @returns {boolean}
   */
  static isErrorStatus = (status) => {
    if (!status) return false

    return Process.status.ERROR === status
  }

  /**
   * Checks if the process has finished
   * @param {ProcessObject} currentProcess The current process object
   * @returns {boolean}
   */
  static hasProcessFinished(currentProcess) {
    if (!currentProcess || !currentProcess.status) return false

    return currentProcess.status === Process.status.FINISHED
  }

  /**
   * Checks if the status received is from a finished process
   * @param {string} status
   * @returns {boolean}
   */
  static isFinishedStatus = (status) => {
    if (!status) return false

    if (status === Process.status.FINISHED) return true

    return false
  }

  /**
   * Checks if the process is running correctly
   * @param {ProcessObject} currentProcess The current process object
   * @returns {boolean}
   */
  static isProcessProcessing(currentProcess) {
    if (!currentProcess || !currentProcess.status) return false

    return (
      currentProcess.status === Process.status.PROCESSING
    )
  }

  /**
   * Checks if the process is waiting to be ran
   * @param {ProcessObject} currentProcess The current process object
   * @returns {boolean}
   */
  static isProcessWaiting(currentProcess) {
    if (!currentProcess || !currentProcess.status) return false

    return currentProcess.status === Process.status.WAITING
  }

  /**
   * Checks if the assistant step should be <Processing /> or <LanguageSelection />
   * if there is no currentSubProgress then it returns the <Loading /> generic step
   *
   * @returns {JSX.Element} The corresponding component for the currentSubProcess
   * @param currentSubProcess
   * @param status
   * @param {boolean} loadLanguageSelectionForUpdates
   */
  static getCurrentProcessingStep(currentSubProcess, status, loadLanguageSelectionForUpdates = false) {
    const { subProcess } = Process
    const { payed = false } = status
    switch (currentSubProcess) {
      case subProcess.ASK_LANGS_TO_TRANSLATE:
        if (payed) {
          return <Loading />
        }

        if (loadLanguageSelectionForUpdates) {
          return <LenguageUpdateHandler />;
        }
        return <TranslationWizard />;
      case null:
      case undefined:
        return <Loading />
      default:
        return <Processing />
    }
  }

  /**
   * Checks if the current process is an update process
   * @param {ProcessObject} currentProcess The current process object
   * @returns {boolean}
   */
  static isProcessUpdating(currentProcess) {
    const { processType } = Process

    if (!currentProcess || !currentProcess.processID) return false

    return currentProcess.processID.includes(processType.update)
  }

  /**
   * Checks if the current process is translating for subtitle in global progress
   * @param {ProcessObject} currentProcess The current process object
   * @returns {boolean}
   */
  static isProcessTranslate(currentProcess) {
    const { processType } = Process

    if (!currentProcess || !currentProcess.processID) return false

    return currentProcess.processID.includes(processType.translate) && Process.processStatus.TRANSLATE.includes(currentProcess.currentSubProcess)
  }

  /**
   * Checks if the current process is analysing for subtitle in global progress
   * @param {ProcessObject} currentProcess The current process object
   * @returns {boolean}
   */
  static isProcessAnalysing(currentProcess) {
    if (!currentProcess || !currentProcess.processID) return false

    return Process.processStatus.ANALYSE.includes(currentProcess.currentSubProcess)
  }

  /**
   * Checks if the current process is downloading for subtitle in global progress
   * @param {ProcessObject} currentProcess The current process object
   * @returns {boolean}
   */
  static isProcessDownloading(currentProcess) {
    if (!currentProcess || !currentProcess.processID) return false

    return Process.processStatus.DOWNLOAD.includes(currentProcess.currentSubProcess)
  }

  /**
   * Checks if the current process is installing language for subtitle in global progress
   * @param {ProcessObject} currentProcess The current process object
   * @returns {boolean}
   */
  static isProcessInstalling(currentProcess) {
    if (!currentProcess || !currentProcess.processID) return false

    return Process.processStatus.INSTALL_LANGUAGES.includes(currentProcess.currentSubProcess)
  }


  /**
   * Checks if the id received is from an update process
   * @param {string} processID The curren process id
   */
  static hasUpdateID(processID) {
    if (!processID) return false

    const { processType } = Process

    return processID.includes(processType.update)
  }

  /**
   * Checks if the current process is a text replace process
   * @param {ProcessObject} currentProcess The current process object
   * @returns {boolean}
   */
  static isProcessReplacing(currentProcess) {
    const { processType } = Process

    if (!currentProcess || !currentProcess.processID) return false

    return currentProcess.processID.includes(processType.replace)
  }

  /**
   * Checks if the id received is from a replace process
   * @param {string} processID The curren process id
   * @returns {boolean}
   */
  static hasReplaceID(processID) {
    if (!processID || typeof processID !== 'string') return false

    const { processType } = Process

    return processID.includes(processType.replace)
  }

  /**
   * Checks if the process fetched exists on the database for the project
   * @param {APIResponse} APIresponse the response received from the server
   * @returns {boolean}
   */
  static isExistingProcess(APIresponse) {
    if (APIresponse.success == null && APIresponse.message == null) return false

    const { success, message } = APIresponse

    if (success === false && message === "Don't exists this process") {
      return false
    }

    return true
  }

  /**
   * Checks if the process has a currentSubprocess defined
   * @param {ProcessObject} currentProcess
   * @returns {boolean}
   */
  static hasCurrentSubProcess(currentProcess) {
    if (!currentProcess || !Process.isProcessProcessing(currentProcess)) return false

    if (currentProcess.currentSubProcess) {
      return true
    }

    return false
  }

  /**
   * Checks if the process is SUM_COLLECTOR
   * @param {ProcessObject} currentProcess
   * @returns {boolean}
   */
  static isSumCollector(currentProcess) {
    if (!currentProcess || !Process.hasCurrentSubProcess(currentProcess)) return false

    if (currentProcess.currentSubProcess === Process.subProcess.SUM_COLLECTOR) {
      return true
    }

    return false
  }

  /**
   * Checks if the process is UPLOAD
   * @param {ProcessObject} currentProcess
   * @returns {boolean}
   */
  static isUpload(process) {
    if (!process || !Process.hasCurrentSubProcess(process)) return false

    if (process.currentSubProcess === Process.subProcess.UPLOAD) {
      return true
    }

    return false
  }

  /**
   * Checks if the process is DOWNLOAD
   * @param {ProcessObject} currentProcess
   * @returns {boolean}
   */
  static isDownload(process) {
    if (!process || !Process.isProcessProcessing(process)) return false

    if (process.currentSubProcess === Process.subProcess.DOWNLOAD) {
      return true
    }

    return false
  }

  /**
   * Checks if the process is TRANSLATION
   * @param {ProcessObject} currentProcess
   * @returns {boolean}
   */
  static isTranslation(process) {
    if (!process || !Process.isProcessProcessing(process)) return false

    if (process.currentSubProcess === Process.subProcess.TRANSLATION) {
      return true
    }

    return false
  }

  /**
   * Checks if the process is ASK_LANGS_TO_TRANSLATE
   * @param {ProcessObject} currentProcess
   * @returns {boolean}
   */
  static isAskLangsToTranslate(process) {
    if (!process || !Process.isProcessProcessing(process)) return false

    if (process.currentSubProcess === Process.subProcess.ASK_LANGS_TO_TRANSLATE) {
      return true
    }

    return false
  }

  /**
   * Checks if the process has already passed the ASK_LANGS_TO_TRANSLATE step
   * @param {ProcessObject} process
   */
  static isProcessTranslating(process) {
    if (!process || !Process.isProcessProcessing(process)) return false
    const { pipeProcess, progress } = process

    const checkoutStep = pipeProcess.findIndex(
      ({ processName }) => processName === Process.subProcess.ASK_LANGS_TO_TRANSLATE,
    )

    // extract the first value with array destructuring
    const [currentStep] = progress ? progress.split('/') : [checkoutStep]

    return currentStep > checkoutStep
  }

  /**
   * Checks if the process has subProgress info
   * @param {ProcessObject} process
   * @returns {boolean}
   */
  static hasSubProgress(process) {
    if (!process || !process.subProgress) {
      return false
    }

    return true
  }

  /**
   * Checks if the current process has started the translation phase
   *
   * @param {ProcessObject} process
   * @returns {boolean}
   */
  static isProcessBeforeTranslation(process) {
    if (!process || !process.currentSubProcess) return false

    return Process.statusBeforeTranslation.includes(process.currentSubProcess)
  }

  static shouldShowResumeProcess(currentProcess, hasConfirmedPlan) {
    const isFinishProcess = Process.hasProcessFinished(currentProcess)

    if (!isFinishProcess) return false

    const isTranslateProcess = currentProcess.processID.includes(Process.processType.translate)

    if (!isTranslateProcess) return false

    if (!hasConfirmedPlan) return true

    const processDate = moment(currentProcess.created)
    const hourSinceTranslation = moment().diff(processDate, 'hours')
    return hourSinceTranslation < 24
  }

  static isProjectProcessCOR(project) {
    const { processType, status } = Process
    if (!project || !project.process) {
      return false
    }
    return project.process.status === status.FINISHED && project.process.coordinator_process_id.includes(processType.replace);
  }

  static isStatusOfTranslating(currentProcess) {
    return Boolean(currentProcess) && Process.statusOfTranslating.includes(currentProcess.currentSubProcess)
  }
}
