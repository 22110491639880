import 'rc-drawer/assets/index.css'
import React from 'react'
import DrawerMenu from 'rc-drawer'

// hoc
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// selectors
import {
  selectIsMobileView,
  selectIsLightTheme,
  selectIsMobileMenuOpen,
  selectIsMenuTop,
} from 'redux/settings/selectors'
import { selectIsFirstSession } from 'redux/user/selectors'
import { selectConfirmedSubscriptionPlan } from 'redux/subscription/selectors';
import { selectIsMenuLeftVisibile } from 'redux/left_menu_visibility/selectors'

// components
import MenuLeft from './MenuLeft'
import MenuTop from './MenuTop'

class AppMenu extends React.Component {
  toggleOpen = () => {
    const { dispatch, isMobileMenuOpen } = this.props
    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isMobileMenuOpen ? 'overflow: hidden; width: 100%; height: 100%;' : '',
      )
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  render() {
    const { isMenuTop, isMobileMenuOpen, isMobileView, isLightTheme, isFirstSession, hasConfirmedSubscriptionPlan, isLeftMenuVisibile } = this.props
    const checkIsFirstSession = isFirstSession === null ? true : isFirstSession;
    const showLeftMenu = isLeftMenuVisibile && (!checkIsFirstSession && hasConfirmedSubscriptionPlan);

    const BootstrappedMenu = () => {
      if (isMobileView && showLeftMenu) {
        return (
          <DrawerMenu
            getContainer={null}
            level={null}
            open={isMobileMenuOpen}
            onMaskClick={this.toggleOpen}
            onHandleClick={this.toggleOpen}
            className={isLightTheme ? 'drawer-light' : ''}
          >
            <MenuLeft />
          </DrawerMenu>
        )
      }
      if (isMenuTop) {
        return <MenuTop />
      }
      return showLeftMenu ? <MenuLeft /> : <></>
    }

    return BootstrappedMenu()
  }
}

const mapStateToProps = state => ({
  isFirstSession: selectIsFirstSession(state),
  isMenuTop: selectIsMenuTop(state),
  isMobileView: selectIsMobileView(state),
  isLightTheme: selectIsLightTheme(state),
  isMobileMenuOpen: selectIsMobileMenuOpen(state),
  hasConfirmedSubscriptionPlan: selectConfirmedSubscriptionPlan(state),
  isLeftMenuVisibile: selectIsMenuLeftVisibile(state)
})

export default connect(mapStateToProps)(withRouter(AppMenu))
