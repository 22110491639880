import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import { omit, get } from 'lodash'

// components
import Loader from 'components/LayoutComponents/Loader'
import { PaymentErrorHandlerModals } from 'components/GlotioComponents/Billing/PaymentErrorHandlerModals'

// actions
import { fetchProjectSuccess } from 'redux/project/actions'
import { checkUserSession, logout, onUpdateAuthInfo, saveUserChanges, setUserAdmin } from 'redux/user/actions'
import { fetchSubscription } from 'redux/subscription/actions'

// selectors
import {
  selectCurrentUser,
  selectIsUserLoading,
  selectUserToken,
  selectIsLogoutLoading,
  selectIsAdminUser,
} from 'redux/user/selectors'
import { selectProjectUrl } from 'redux/project/selectors'
import {
  selectCurrentSubscription,
  selectIsLoadingSubscription,
  selectSubscriptionStatus
} from 'redux/subscription/selectors'
import { selectFetchingLanguages, selectLanguagesLoadingMessage } from 'redux/languages/selectors'

// utils
import { fallbackLanguage } from 'utils/userLanguageFallback'
import { clearStorageExcept } from 'utils/clearStorageExcept'

// constants
import { CURRENT_USER_STORAGE_KEY } from 'constants/session_storage_keys.contants'
import {ROUTES, OPEN_ROUTES_ON_PAYMENT_ERROR} from 'constants/routes.constants'
import { SUBSCRIPTION_STATUS_WITH_RESTRICTIONS } from 'constants/status_subscriptions'
import { BROADCAST_PROPAGATE_SESSION } from 'constants/name_broadcast_channel.constants'

// layouts
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'
import Storage from "../utils/storage";
import Welcome from './Welcome'
import GlotioURL from '../utils/GlotioURL'

const Layouts = {
  public: PublicLayout,
  login: AuthLayout,
  main: MainLayout,
  welcome: Welcome
}

class IndexLayout extends React.Component {
  previousPath = ''

  constructor(props) {
    super(props)
    this.currentUrl = new GlotioURL()

    this.state = {
      authRoutes: [
        'login',
        'password-reset',
        'password-forgot',
        'signup',
        'email-verification',
        'direct-auth',
        'admin-direct-auth',
        'open',
        'signin-disabled',
      ],
      welcomeRoutes: [
        'welcome-dashboard-auth',
        'welcome-dashboard-no-auth'
      ],
      isLoadedFromPrestashop: window.location !== window.parent.location,
    }
  }


  /**
   * Read project data received from url
   * Checks for a session stored in the browser
   */
  componentDidMount = async () => {
    const {
      location,
      history,
      checkCurrentSession,
      changeLocale,
    } = this.props
    const { isLoadedFromPrestashop } = this.state

    try {
      const broadcast = new BroadcastChannel(`${BROADCAST_PROPAGATE_SESSION}-glotio-broadcast-channel`)
      // Listener for message events on the broadcast channel.
      broadcast.onmessage = (event) => {
        // Check if the received message is a request to retrieve the session and if sessionStorage has data.
        if (event.data && event.data.message === 'retrieve-session' && window.sessionStorage.length) {
          // Check if sessionStorage contains the key for the current user from another tab.
          const hasCurrentUserStorageKeyFromOtherTab = window.sessionStorage.getItem(CURRENT_USER_STORAGE_KEY);
          if (hasCurrentUserStorageKeyFromOtherTab) {
            // Clone the current sessionStorage.
            const currentSession = { ...window.sessionStorage }
            // Omit specific keys (e.g., 'app.project') from the session data to be sent.
            const sessionToSend = omit(currentSession, ['app.project'])
            // Broadcast the session data to other tabs.
            broadcast.postMessage({ message: 'shared-session', session: JSON.stringify(sessionToSend) })
          }
        }
        // Check if the received message is a shared session and the current path is not the direct auth route.
        if (event.data && event.data.message === 'shared-session' && location.pathname !== ROUTES.DIRECT_AUTH) {
          // Check again if the sessionStorage does not contain the current user's key.
          const hasCurrentUserStorageKeyFromOtherTab = window.sessionStorage.getItem(CURRENT_USER_STORAGE_KEY);
          if (!hasCurrentUserStorageKeyFromOtherTab) {
            // Parse the received session data.
            const persistedSession = JSON.parse(event.data.session)
            // Extract the keys from the persisted session.
            const persistedItems = Object.keys(persistedSession);
            // Define a function to update the sessionStorage with the received data.
            const shouldUpdateStorage = () => {
              // Update sessionStorage with each item from the persisted session.
              persistedItems.forEach((item) => window.sessionStorage.setItem(item, persistedSession[item]));
              // Perform a check on the current session based on the current route.
              checkCurrentSession(this.currentUrl.getRoute());
            }
            // Check if the session should be updated based on a condition (e.g., not loaded from Prestashop).
            if (!isLoadedFromPrestashop) {
              shouldUpdateStorage();
            } else {
              // Additional checks for updating storage based on project URLs.
              const incomingURL = JSON.parse(get(persistedSession, 'CURRENT_PROJECT_STORAGE_KEY', {})).url;
              const currentProjectURL = JSON.parse(sessionStorage.getItem('app.project') || '{}').url;
              // Update storage if the incoming URL matches the current project URL.
              if (incomingURL && currentProjectURL && (incomingURL === currentProjectURL)) {
                shouldUpdateStorage();
              }
            }
          }
        }
      }
    } catch (error) {
      console.error('brodcastChannel is not supported: ', error)
    }

    // TODO Done for Classic Plan
    if (localStorage.getItem('show_classic_modal') === null) {
      localStorage.setItem('show_classic_modal', true)
    }

    setUserAdmin(Storage.getIsAdminUser())

    await this.getProjectDataFromSearchParams(location.search);

    if (location.search !== '?check=false') {
      checkCurrentSession(this.currentUrl.getRoute())
    }

    window.scrollTo(0, 0)
    history.listen(() => {
      window.scrollTo(0, 0)

      if (typeof vgo === 'function') {
        vgo('update')
        vgo('process')
      }
    })

    // Looks for the last lang the user used
    try {
      const lastUserLang = JSON.parse(localStorage.getItem('app.settings.locale'))
      if (lastUserLang) {
        changeLocale(lastUserLang)
      }
    } catch (err) {
      console.info('Cookies are disabled')
    }

  }

  /**
   * Reads the query params from a url and turns it into an object
   * @param {string} searchParams The query param string - ie. '?user=example"
   * @returns {Promise} A Promise that resolves into an object with the data from the string
   * or false if there is no apikey
   */
  getProjectDataFromSearchParams = (searchParams) => {
    const searchQuery = new GlotioURL(searchParams).getAllSearchParams()

    if (!searchQuery) return null

    const { changeLocale, saveProjectData } = this.props

    return new Promise((resolve) => {
      const { email, apikey, url, user_lang } = searchQuery

      if (user_lang) {
        const userValidLang = fallbackLanguage(user_lang)
        searchQuery.user_lang = userValidLang
        changeLocale(userValidLang)
        localStorage.setItem("app.settings.locale", JSON.stringify(userValidLang))
      }

      if (email && apikey && url) {
        saveProjectData(searchQuery)
        const { isLoadedFromPrestashop } = this.state
        if (isLoadedFromPrestashop) {
          sessionStorage.setItem("app.project", JSON.stringify(searchQuery))
        }

      }
      resolve(searchQuery)
    })
  }

  /**
   * Returns the first slug of the current url
   * @returns {string} The text of the first slug - ie. '/login'
   */
  getFirstSlug = () => {
    const {
      location: { pathname },
    } = this.props
    const [, firstSlug] = pathname.split('/')
    return firstSlug
  }

  /**
   * Decides which layout to render depending on url and user state
   * @returns {string} The name of the layout
   */
  getLayout = () => {
    const { authRoutes, welcomeRoutes } = this.state
    const {
      location: { pathname },
    } = this.props

    const isWelcomeRoute = welcomeRoutes.includes(this.getFirstSlug())
    const isAuthRoute = authRoutes.includes(this.getFirstSlug())

    if (pathname === '/') {
      return 'public'
    }
    if (isWelcomeRoute) {
      return 'welcome'
    }
    if (isAuthRoute) {
      return 'login'
    }
    return 'main'
  }

  /**
   * ### Shares session status between browser tabs
   * - If a new tab is open (it has no project info the first session found its shared with it).
   * - If a prestashop module is open, then check if the project is the same before sharing the session.
   * @param {string} key The key assigned to the storage action
   * @param {string} newValue The actual value of the storage action
   */
  handleMultipleSessions = ({ key, newValue }) => {
    const {
      logoutUser,
      logoutLoading,
      currentUser,
      userToken,
      updateAuthInfo
    } = this.props

    const { isLoadedFromPrestashop } = this.state

    try {

      if (isLoadedFromPrestashop && key === 'logged' && newValue) {
        const loginData = JSON.parse(newValue)
        updateAuthInfo(loginData)
      }

      if (
        key === 'app.unsetSessionStorage' &&
        window.sessionStorage.length &&
        !logoutLoading
      ) {
        const data = JSON.parse(newValue)
        if (data && currentUser && userToken) {
          logoutUser(data)
        } else if (data) {
          clearStorageExcept('app.project', 'sessionStorage')
        }
      }
    } catch (e) {
      console.warn('could not reuse session', e)
    }
  }

  render() {

    const { isLoadedFromPrestashop } = this.state
    const currentRouteUrl = this.currentUrl.getRoute()
    const {
      children,
      location: { pathname, search },
      currentUser,
      userToken,
      userLoading,
      isLoadingSubscription,
      isLoadingLanguages,
      subscriptionStatus,
      history,
      currentSubscription,
      loadingLanguagesMessage
    } = this.props

    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    let paymentModal = <></>
    if (!OPEN_ROUTES_ON_PAYMENT_ERROR.includes(pathname) && SUBSCRIPTION_STATUS_WITH_RESTRICTIONS.includes(subscriptionStatus)) {
      paymentModal = <PaymentErrorHandlerModals history={history} currentSubscription={currentSubscription} />
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    const Container = Layouts[this.getLayout()]
    const isUserAuthorized = currentUser && userToken
    const isUserLoading = userLoading
    const isLoginLayout = this.getLayout() === 'login'
    const isWelcomeLayout = this.getLayout() === 'welcome'

    window.addEventListener('storage', this.handleMultipleSessions)

    const BootstrappedLayout = () => {
      // display the loader when the user is in the process of authorisation, is not yet authorised and is not on the login pages or is loading language and subscription data.
      if (isLoadingSubscription || isLoadingLanguages || (isUserLoading && !isUserAuthorized && !isLoginLayout)) {
        return <Loader text={loadingLanguagesMessage} />
      }

      // Redirects to signup or login when enter from Prestashop (ifram)
      if (isLoadedFromPrestashop && !isUserAuthorized && pathname === '/') {
        return <Redirect to={ROUTES.WELCOME_DASHBOARD_NO_AUTH} />
      }

      // redirect to login page if current is not login page and user not authorized
      if (!isLoginLayout && !isLoadedFromPrestashop && !isUserAuthorized && pathname !== ROUTES.LOGIN) {
        const loginUrl = new GlotioURL(ROUTES.LOGIN)

        if (this.currentUrl.searchParams.has('redirect')) {
          loginUrl.searchParams.set('redirectTo', `${pathname}?${search.replace('?', '').replace('redirect=1', '')}`)
        } else if (currentRouteUrl !== '/?check=false') {
          loginUrl.searchParams.set('redirectTo', currentRouteUrl)
        }

        return <Redirect to={loginUrl.getRoute()} />
      }

      // redirect to main dashboard when user on login page and authorized
      if ((isLoginLayout || isWelcomeLayout) && isUserAuthorized) {
        if (isLoadedFromPrestashop !== null) {
          if (isLoadedFromPrestashop && pathname !== ROUTES.WELCOME_DASHBOARD_AUTH) return <Redirect to={ROUTES.WELCOME_DASHBOARD_AUTH} />
          if (!isLoadedFromPrestashop) return <Redirect to="/" />
        }
      }

      // in other case render previously set layout
      return (
        <Container>
          {children}
        </Container>
      )
    }

    return (
      <Fragment>
        <Helmet titleTemplate="Glotio | %s" title="Advanced translation system" />
        {paymentModal}
        {BootstrappedLayout()}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  userToken: selectUserToken(state),
  currentUser: selectCurrentUser(state),
  userLoading: selectIsUserLoading(state),
  logoutLoading: selectIsLogoutLoading(state),
  projectUrl: selectProjectUrl(state),
  isAdminUser: selectIsAdminUser(state),
  isLoadingSubscription: selectIsLoadingSubscription(state),
  subscriptionStatus: selectSubscriptionStatus(state),
  isLoadingLanguages: selectFetchingLanguages(state),
  currentSubscription: selectCurrentSubscription(state),
  loadingLanguagesMessage: selectLanguagesLoadingMessage(state)
})

const mapDispatchToProps = (dispatch) => ({
  saveProjectData: (data) => dispatch(fetchProjectSuccess(data)),
  changeLocale: (locale) => dispatch(saveUserChanges({ lang: locale })),
  checkCurrentSession: (url) => dispatch(checkUserSession(url)),
  logoutUser: (data) => dispatch(logout(data)),
  fetchCurrentSubscription: (data) => dispatch(fetchSubscription(data)),
  updateAuthInfo: loginData => dispatch(onUpdateAuthInfo(loginData))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IndexLayout))
