
import { all, takeLatest, takeEvery, put, call, select, delay } from 'redux-saga/effects'

// components
import { message } from 'antd'

// utils
import { replace } from 'connected-react-router'
import langsList from 'services/languages-list'

// actions
import actions, {
  loginUserSuccess,
  loginUserFail,
  checkAccountSuccess,
  fetchUserStart,
  fetchUserSuccess,
  setCurrentUser,
  renewSessionStart,
  saveUserChanges,
  setUserAdmin
} from 'redux/user/actions'
import { fetchProjectStart, setProjectId, setProjectEmail, fetchProjectSuccess } from 'redux/project/actions'
import { fetchLanguagesStart } from 'redux/languages/actions'
import { changeCurrentLocale } from 'redux/settings/actions'
import { fetchSubscription } from 'redux/subscription/actions'

// models
import { checkIfAccountExistsRequest, sendVerificationAccountToEmailRequest } from 'models/api/account'
import { loginAdminRequest, loginUserRequest, logoutUserRequest } from 'models/api/auth'
import { getUserByIdRequest } from 'models/api/user'

// selectors
import { selectCurrentProject } from 'redux/project/selectors'
import {
  selectCurrentUser,
  selectTokenExpirationDate,
} from 'redux/user/selectors'

// utils
import Storage from "utils/storage"
import { clearStorageExcept } from 'utils/clearStorageExcept'
import GlotioURL from 'utils/GlotioURL'

// constants
import { CURRENT_PROJECT_STORAGE_KEY, CURRENT_USER_STORAGE_KEY } from 'constants/session_storage_keys.contants'
import { ROUTES } from 'constants/routes.constants'
import { BROADCAST_PROPAGATE_SESSION } from 'constants/name_broadcast_channel.constants'


/**
 * Updates the current user token data on the redux store as well as local or session storages
 */
function* updateToken({ payload }) {
  const currentUser = yield select(selectCurrentUser)

  const updatedUser = { ...currentUser }
  updatedUser.token = payload
  const oneWeekToMiliseconds = 60000 * 60 * 24 * 7
  updatedUser.token_exp = Date.now() + oneWeekToMiliseconds
  yield put(setCurrentUser(updatedUser))

  // let stored
  if (localStorage.getItem(CURRENT_USER_STORAGE_KEY)) {
    // stored = JSON.parse(localStorage.getItem('app.currentUser'))
    // stored.token = payload
    localStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(updatedUser))
    return true
  }

  if (sessionStorage.getItem(CURRENT_USER_STORAGE_KEY)) {
    // stored = JSON.parse(sessionStorage.getItem('app.currentUser'))
    // stored.token = payload
    sessionStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(updatedUser))
    return true
  }

  return false
}

function* checkUserSession({ payload }) {
  const isLoadedFromIframe = window.location !== window.parent.location
  try {
    const storedUser =
      JSON.parse(localStorage.getItem(CURRENT_USER_STORAGE_KEY)) ||
      JSON.parse(sessionStorage.getItem(CURRENT_USER_STORAGE_KEY))
    const storedProject =
      JSON.parse(localStorage.getItem(CURRENT_PROJECT_STORAGE_KEY)) ||
      JSON.parse(sessionStorage.getItem(CURRENT_PROJECT_STORAGE_KEY))
    const currentProject = yield select(selectCurrentProject)

    // si hay datos en la url (currentProject)
    if (currentProject) {
      if (storedUser && storedProject && storedProject.url === currentProject.url) {
        yield call(logStoredUser, storedUser)
        if (isLoadedFromIframe) {
          yield put(replace(ROUTES.WELCOME_DASHBOARD_AUTH))
        } else {
          yield put(replace(payload || '/'))
        }
      }
    } else if (storedUser) {
      // url sin datos (!currentProject)
      yield call(logStoredUser, storedUser, payload)
    }

    // Ask other tabs for the session storage
    const broadcast = new BroadcastChannel(`${BROADCAST_PROPAGATE_SESSION}-glotio-broadcast-channel`)
    broadcast.postMessage({message: 'retrieve-session'})

    if (storedProject) {
      yield put(setProjectId(storedProject.projectId))
      yield put(fetchProjectStart())
      yield put(fetchSubscription(storedProject.projectId))

      if (!payload.includes(ROUTES.ASSISTANT)) {
        yield put(fetchLanguagesStart())
      }
    }
  } catch (err) {
    if (!isLoadedFromIframe) {
      yield put(replace(ROUTES.SIGN_IN_DISABLED))
    }
  }
}

function* logStoredUser(userData, redirection) {
  const origin = redirection ? 'APP' : 'Iframe'   // detect where the user null comes from
  yield put(loginUserSuccess(userData))
  yield put(fetchUserStart(userData.userId, `logStoreUser(${origin})`))
  yield put(replace(redirection))
}

/**
 * Checks the time left for the token to be expired and
 */
function* checkTokenExpiration() {
  const expirationDate = yield select(selectTokenExpirationDate)
  if (!expirationDate) return

  const timeLeftToExpiration = expirationDate - Date.now()
  const hoursLeftToExpire = Math.round(timeLeftToExpiration / 3600000)

  if (hoursLeftToExpire <= 24) {
    // wait 3s and show the modal to renew o close the session
    yield delay(3000)
    yield put(renewSessionStart())
  } else {
    // waits 1m and check again the token expiration status
    yield delay(60000)
    yield checkTokenExpiration()
  }
}


/**
 * This function check if the auth session recieved have the same project url, then copy the session to update the auth status
 */
function* updateAuthInfo({payload : {currentProject, currentUser}}) {

  const isLoadedFromIframe = window.location !== window.parent.location
  if (!isLoadedFromIframe || !currentProject || !currentUser) return

  const selectorProjectIframe = yield select(selectCurrentProject)
  const storedProjectIframe = sessionStorage.getItem('app.project')
  let iframeProject = false

  if (selectorProjectIframe) {
    iframeProject = selectorProjectIframe
  } else if (storedProjectIframe) {
    iframeProject = JSON.parse(storedProjectIframe)
  }

  if (!iframeProject) return

  if (currentProject.url === iframeProject.url) {

    const { id: projectId, url, platform } = currentProject
    const projectToStore = { projectId, url, platform }

    const { accountId, token, userId, zendeskToken} = currentUser
    const userToStore = { accountId, token, userId, zendeskToken }
    yield sessionStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(userToStore))
    yield sessionStorage.setItem(CURRENT_PROJECT_STORAGE_KEY, JSON.stringify(projectToStore))

    yield put(fetchProjectSuccess(currentProject))
    yield put(setProjectId(projectId))
    yield put(loginUserSuccess(currentUser))
    yield put(fetchUserStart(userId, 'updateAuthInfo'))   // detect where the user null comes from
    yield put(fetchLanguagesStart())
    yield put(fetchSubscription(projectId))

  }

}

/**
 * This function is in charge of obtaining the data required to make a correct authentication required from the iframe enviroments.
 */
function* spreadAuthInfo () {
  const isLoadedFromIframe = window.location !== window.parent.location
  if (isLoadedFromIframe) return
  const currentProject = yield select(selectCurrentProject)
  const currentUser = yield select(selectCurrentUser)
  if (!(currentProject && 'id' in currentProject) || !currentUser) {
    yield delay(3000)
    yield spreadAuthInfo()
    return
  }
  yield localStorage.setItem('logged', JSON.stringify({currentProject, currentUser }))
  yield localStorage.removeItem('logged')
}

function* loginUser({ payload }) {
  const { email, password, remember, redirectTo, autoLogin = false } = payload
  const { shop = null } = payload // este parametro se usa al loguearse desde loginAdmin

  try {
    let resultlogin
    if (shop !== null) {
      Storage.setIsAdminUser()
      yield put(setUserAdmin(Storage.getIsAdminUser()))
      resultlogin = yield loginAdminRequest({ loginData: { email, password, project_id: shop } })
    } else if (autoLogin) {
      Storage.setIsAdminUser()
      yield put(setUserAdmin(Storage.getIsAdminUser()))
      resultlogin = {
        result: {
          success: true,
          ...payload
        }
      }
    } else {
      // yield put(setUserAdmin(false))
      resultlogin = yield loginUserRequest({ loginData: { email, password } })
    }

    const { result } = resultlogin
    if (!result) {
      yield put(loginUserFail('There was a connection error.'))
    }
    if (result.success === true) {
      const { token, projectId, userId, accountId, zendeskToken } = result
      const sessionData = { token, userId, accountId, zendeskToken }
      const sessionAppProject = sessionStorage.getItem('app.project') || '{}'
      const storedProjectIframe = JSON.parse(sessionAppProject)
      // antes de iniciar la sesión borra todas las posibles sesiones abiertas

      yield call(closeOlderSessions)
      yield put(loginUserSuccess(result))
      yield put(fetchUserStart(userId, 'loginUser'))   // detect where the user null comes from
      yield put(setProjectId(projectId))
      yield put(fetchProjectStart({ storedProjectIframe }))
      yield put(fetchLanguagesStart())
      yield put(fetchSubscription(projectId))

      const currentProject = yield select(selectCurrentProject)
      const projectData = {
        projectId,
        ...(currentProject && currentProject.url && { url: currentProject.url }),
      }
      if (remember) {
        const oneWeekToMiliseconds = 60000 * 60 * 24 * 7
        sessionData.token_exp = Date.now() + oneWeekToMiliseconds
        yield localStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(sessionData))
        yield localStorage.setItem(CURRENT_PROJECT_STORAGE_KEY, JSON.stringify(projectData))
      } else {
        yield sessionStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(sessionData))
        yield sessionStorage.setItem(CURRENT_PROJECT_STORAGE_KEY, JSON.stringify(projectData))
      }

      const urlParams = new URLSearchParams(redirectTo)
      if (urlParams.has('redirectTo')) {
        yield put(replace(urlParams.get('redirectTo')))
      }

      yield spreadAuthInfo()

    }

    if (result.success === false) {
      if (result.verified_account !== undefined && result.verified_account === false) {
        yield put(loginUserFail('Please verify your account to log in.'))
        yield put(setProjectEmail(payload.email))
        yield sendVerificationAccountToEmailRequest({ email: payload.email })
        yield put(replace(ROUTES.EMAIL_VERIFICATION))
      } else {
        yield put(loginUserFail('The username or password you have entered is incorrect.'))
      }
    }
  } catch (err) {
    yield put(loginUserFail('There was a connection error.'))
  }
}

/**
 * Forces othes tabs with opened sessions to logout.
 * This way we ensure the user can only work in one project at a time.
 * Preventing from bugs difficult to maintain generated by how the different browsers work
 */
function* closeOlderSessions() {
  const currentProject = yield select(selectCurrentProject)

  window.localStorage.setItem(
    'app.unsetSessionStorage',
    JSON.stringify({
      date: Date.now(),
      fetch: false,
      login: true,
      ...(currentProject && { project: currentProject.url }),
    }),
  )
}

/**
 * Removes the user session data and redirects to login page
 */
function* logoutUser({ payload = { fetch: false, login: false, project: null } }) {

  if (typeof window.zE === 'function') {
    window.zE("messenger", "logoutUser")
  }

  const { fetch = false, login = false, project = null } = payload

  if (payload.fetch) {
    yield logoutUserRequest({})
  }

  const currentProject = yield select(selectCurrentProject)

  /**
   * If login is false, close the session of the same project url
   * If login is true, close the session if this is not the same project
   */
  if (!currentProject || (login && currentProject.url !== project)) {
    // remove all session data stored in session and local storages
    clearStorageExcept(['app.settings.locale'])
    clearStorageExcept(['app.project'], 'sessionStorage')

    // reload the page with a key to not ask for session to other tabs
    window.location.replace('/?check=false')
  }

  if (!login && currentProject.url === project) {
    // remove all session data stored in session and local storages

    clearStorageExcept(['app.settings.locale'])
    clearStorageExcept(['app.project'], 'sessionStorage')

    yield delay(800)
    // call other tabs to close session too
    window.localStorage.setItem(
      'app.unsetSessionStorage',
      JSON.stringify({
        date: Date.now(),
        fetch,
        login,
        project: currentProject.url,
      }),
    )

    // reload the page with a key to not ask for session to other tabs
    window.location.replace('/?check=false')
    clearStorageExcept(['app.project'], 'sessionStorage')
  }
}

function* showLogoutError({ payload }) {
  yield message.error(payload)
}

function* checkIfAccountExists({ payload: { apikey, email } }) {
  let response = null

  try {
    const {
      success,
      accountExists,
      validated,
      emailValidationExpired,
    } = yield checkIfAccountExistsRequest({ apikey, email })

    if (success) {
      yield put(
        checkAccountSuccess({
          accountExists,
          validated,
          emailValidationExpired,
        }),
      )

      response = { accountExists }
    }
  } catch (err) {
    yield message.error(err.message)
  }

  return response
}

/**
 * Handles the action of fetching the user profile and
 * updates the currentUser info with the recieved data
 *
 * @param {{type: String, payload: Number, origin: string}} action The id of the user
 */
function* fetchUser(action) {
  const { payload, origin } = action

  const reload = () => {
    clearStorageExcept(['app.settings.locale'])
    clearStorageExcept(['app.project'], 'sessionStorage')
    const actualUrl = new GlotioURL()
    if (actualUrl.pathname === ROUTES.DIRECT_AUTH) {
      const loginUrl = new GlotioURL(ROUTES.LOGIN)
      window.location = loginUrl.href
    } else {
      window.location.reload()
    }
  }

  if(payload) {
    const { result, error } = yield getUserByIdRequest({ userId: payload })

    if (!error) {
      if (result.success) {
        yield put(fetchUserSuccess(result.object))

        const { email, lang } = result.object
        // check the user lang is supported by the UI and change the user lang in case it doesn't
        const isSupportedLang = langsList.some((item) => item.value === lang)
        if (isSupportedLang) {
          yield put(changeCurrentLocale(lang))
        } else {
          yield put(changeCurrentLocale('en'))
          yield put(saveUserChanges({ lang: 'en' }))
        }

        if (typeof vgo === 'function') {
          vgo('setEmail', email)
          vgo('process')
        }
      }
    } else {
      console.error(`${origin} => Fail request in fetchUser: `, error)
      yield call(closeOlderSessions)
      reload()
    }
  } else  {
    console.error(`${origin} => Invalid Payload in fetchUser: `, payload)
    yield call(closeOlderSessions)
    reload()
  }
}

// listening to user redux actions
function* onCheckUserSession() {
  yield takeLatest(actions.CHECK_USER_SESSION, checkUserSession)
}

function* onUserLogin() {
  yield takeEvery(actions.LOGIN_START, loginUser)
}

function* onLoginSuccess() {
  yield takeLatest(actions.LOGIN_SUCCESS, checkTokenExpiration)
}

function* onUserLogout() {
  yield takeLatest(actions.LOGOUT, logoutUser)
}

function* onLogoutFail() {
  yield takeLatest(actions.LOGOUT_FAIL, showLogoutError)
}

function* onCheckIfAccountExists() {
  yield takeLatest(actions.CHECK_ACCOUNT, checkIfAccountExists)
}

function* onFetchUserStart() {
  yield takeEvery(actions.FETCH_USER_START, fetchUser)
}

function* onUpdateToken() {
  yield takeEvery(actions.UPDATE_TOKEN, updateToken)
}

function* onCloseOlderSessions() {
  yield takeLatest(actions.CLOSE_OLDER_SESSIONS, closeOlderSessions)
}

// updates the token on the browser when the session is renewed
// launches the checking for the new expiration token
function* onRenewSessionToken() {
  yield takeLatest(actions.RENEW_SESSION_SUCCESS, updateToken)
  yield takeLatest(actions.RENEW_SESSION_SUCCESS, checkTokenExpiration)
}

function* onSpreadAuthInfo() {
  yield takeLatest(actions.SPREAD_AUTH_INFO, spreadAuthInfo)
}

function* onUpdateAuthInfo() {
  yield takeEvery(actions.UPDATE_AUTH_INFO, updateAuthInfo)
}

export default function* userSaga() {
  yield all([
    call(onCheckUserSession),
    call(onUserLogin),
    call(onUserLogout),
    call(onLogoutFail),
    call(onCheckIfAccountExists),
    call(onFetchUserStart),
    call(onUpdateToken),
    call(onLoginSuccess),
    call(onRenewSessionToken),
    call(onCloseOlderSessions),
    call(onSpreadAuthInfo),
    call(onUpdateAuthInfo)
  ])
}
