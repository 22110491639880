import React from 'react';

// constants
import { ROUTES } from "constants/routes.constants"
import { PLATFORMS } from 'constants/platforms.contants'
import { CURRENT_PROJECT_STORAGE_KEY } from 'constants/session_storage_keys.contants'
import { CHILDREN_MENU_ITEMS_EXCLUDED_BY_ROUTES } from 'constants/menu_item_exluded.contants'

// icons
import { ReactComponent as DashBoardIcon } from 'assets/images/icons/icon_menu_dashboard.svg'
import { ReactComponent as AddLanguagesIcon } from 'assets/images/icons/icon_menu_add_languages.svg'
import { ReactComponent as UpdateLanguagesIcon } from 'assets/images/icons/icon_menu_update_languages.svg'
import { ReactComponent as MyLanguagesLanguagesIcon } from 'assets/images/icons/icon_menu_my_languages.svg'
import { ReactComponent as AdvancedSettingsIcon } from 'assets/images/icons/icon_menu_advanced_settings.svg'
import { ReactComponent as TranslationsHistoryIcon } from 'assets/images/icons/icon_menu_translations_history.svg'
import { ReactComponent as ExcludedWordsIcon } from 'assets/images/icons/icon_menu_excluded_words.svg'
import { ReactComponent as DictionaryIcon } from 'assets/images/icons/icon_menu_dictionary.svg'
import { ReactComponent as FindAndReplaceIcon } from 'assets/images/icons/icon_menu_find_and_replace.svg'
import { ReactComponent as MyProfileIcon } from 'assets/images/icons/icon_menu_my_profile.svg'
import { ReactComponent as SubscriptionsIcon } from 'assets/images/icons/icon_menu_subscriptions.svg'
import { ReactComponent as PaymentsIcon } from 'assets/images/icons/icon_menu_payments.svg'
import { ReactComponent as FaqIcon } from 'assets/images/icons/icon_menu_faq.svg'
import { ReactComponent as NotificationsIcon } from 'assets/images/icons/icon_menu_notifications.svg'
import { ReactComponent as TechnicalSupportIcon } from 'assets/images/icons/icon_technical_support.svg'

const FIND_AND_REPLACE = 'Find and Replace'
const EXCLUDE_WORDS = 'Excluded Words'
const DICTIONARY = 'Smart dictionary'

export const optionsWithTools = [FIND_AND_REPLACE, EXCLUDE_WORDS, DICTIONARY]

export default function getLeftMenuData(isUpdating = false) {

  const menuData = [
    {
      key: 'Dashboard',
      url: ROUTES.DASHBOARD,
      icon: props => <DashBoardIcon {...props} />,
      disabled: isUpdating,
    },
    {
      divider: true,
      key: 'translation',
    },
    {
      key: 'Add new languages',
      url: ROUTES.ASSISTANT,
      icon: props => <AddLanguagesIcon {...props} />,
      disabled: isUpdating,
    },
    {
      key: 'Update languages',
      url: ROUTES.UPDATES,
      icon: props => <UpdateLanguagesIcon {...props} />,
      disabled: isUpdating,
      children: isUpdating
        ? undefined
        : [
            {
              key: 'Manuals',
              disabled: isUpdating,
              url: ROUTES.MANUAL_UPDATES,
            },
            {
              key: 'Configure updates',
              disabled: isUpdating,
              url: ROUTES.AUTOMATICALLY_UPDATES,
            }
          ],
    },
    {
      key: 'My languages',
      url: ROUTES.MY_LANGUAGES,
      icon: props => <MyLanguagesLanguagesIcon {...props} />,
      disabled: isUpdating,

    },
    {
      key: 'Advanced Settings',
      url: ROUTES.ADVANCED_OPTIONS,
      icon: props => <AdvancedSettingsIcon {...props} />,
      disabled: isUpdating,
    },
    {
      key: 'Translation history',
      url: ROUTES.HISTORY,
      icon: props => <TranslationsHistoryIcon {...props} />,
      disabled: isUpdating,
    },
    {
      divider: true,
      key: 'tools',
    },
    {
      key: EXCLUDE_WORDS,
      url: ROUTES.EXCLUDED_WORDS,
      icon: props => <ExcludedWordsIcon {...props} />,
      disabled: isUpdating,
    },
    {
      key: DICTIONARY,
      url: ROUTES.DICTIONARY,
      icon: props => <DictionaryIcon {...props} />,
      disabled: isUpdating,
    },
    {
      key: FIND_AND_REPLACE,
      url: ROUTES.FIND_AND_REPLACE,
      icon: props => <FindAndReplaceIcon {...props} />,
      disabled: isUpdating,
    },
    {
      divider: true,
      key: 'user area',
    },
    {
      key: 'My Profile',
      url: ROUTES.MY_PROFILE,
      icon: props => <MyProfileIcon {...props} />,
      disabled: isUpdating,
      children: isUpdating
        ? undefined
        : [
            {
              key: 'User Info',
              disabled: isUpdating,
              url: ROUTES.MY_PROFILE_USER,
            },
            {
              key: 'Project Info',
              disabled: isUpdating,
              url: ROUTES.MY_PROFILE_PROJECT,
            },
            {
              key: 'Password',
              disabled: isUpdating,
              url: ROUTES.MY_PROFILE_PASSWORD,
            },
          ],
    },
    {
      key: 'Subscriptions',
      url: ROUTES.SUBSCRIPTION_DASHBOARD,
      icon: props => <SubscriptionsIcon {...props} />,
      disabled: isUpdating,
    },
    {
      key: 'Notifications',
      url: ROUTES.NOTIFICATIONS,
      icon: props => <NotificationsIcon {...props} />,
      disabled: isUpdating,
    },
    {
      key: 'Payment and billings',
      url: ROUTES.PAYMENTS,
      icon: props => <PaymentsIcon {...props} />,
      disabled: isUpdating,
      children: isUpdating
        ? undefined
        : [
            {
              key: 'Payment details',
              disabled: isUpdating,
              url: ROUTES.PAYMENTS_BILLING,
            },
            {
              key: 'Invoice History',
              disabled: isUpdating,
              url: ROUTES.PAYMENTS_HISTORY,
            },
          ],
    },
    {
      divider: true,
      key: 'help',
    },
    {
      key: 'FAQ',
      url: 'FAQ_URL',
      target: '_blank',
      icon: props => <FaqIcon {...props} />,
      disabled: false,
    },
    {
      key: 'Technical support',
      url: ROUTES.TECHNICAL_SUPPORT,
      icon: props => <TechnicalSupportIcon {...props} />,
      disabled: isUpdating,
    },
  ]

  const parseLeftMenuData = excludeChildItemsByUrlKey(menuData)

  return parseLeftMenuData
}

// Añadir una funcion que retorne el contenido de los submenus indicando la url del parent
export const getSubmenusData = (disabled = false) => {

  const subMenuData = [
    {
      parent_url: ROUTES.MY_PROFILE,
      children: [
        {
          key: 'User Info',
          url: ROUTES.MY_PROFILE_USER,
          disabled,
        },
        {
          key: 'Project Info',
          url: ROUTES.MY_PROFILE_PROJECT,
          disabled,
        },
        {
          key: 'Password',
          url: ROUTES.MY_PROFILE_PASSWORD,
          disabled,
        },
      ],
    },
    {
      parent_url: ROUTES.MY_LANGUAGES
    },
    {
      parent_url: ROUTES.ADVANCED_OPTIONS
    },
    {
      parent_url: ROUTES.PAYMENTS,
      children: [
        {
          key: 'Payment details',
          url: ROUTES.PAYMENTS_BILLING,
          disabled,
        },
        {
          key: 'Invoices',
          url: ROUTES.PAYMENTS_HISTORY,
          disabled,
        },
      ],
    },
  ]

  const parseSubMenuData = excludeChildItemsByUrlKey(subMenuData)
  return parseSubMenuData

}


/**
 * Excludes specific child items based on URL key.
 *
 * @param {Array} data - Array of menu items.
 * @returns {Array} - Array of menu items after exclusion based on URL key.
 */

 function excludeChildItemsByUrlKey(data) {
  const storedProject = sessionStorage.getItem(CURRENT_PROJECT_STORAGE_KEY) || "{}"
  const { platform = PLATFORMS.PRESTASHOP } = JSON.parse(storedProject)

  const urlKeys = CHILDREN_MENU_ITEMS_EXCLUDED_BY_ROUTES[platform] || []

  if (!Array.isArray(data) || !Array.isArray(urlKeys)) {
    return data
  }

  return data.map(item => {
    if (Array.isArray(item.children)) {
      return {
        ...item,
        children: item.children.filter(child => !urlKeys.includes(child.url))
      }
    }
    return item
  })
}


